import React from "react";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

export const CGauge = (props) => {
    return <Gauge
        value={91}
        startAngle={0}
        endAngle={360}
        innerRadius="82%"
        outerRadius="100%"
        height={250}
        sx={{
            [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: 'translate(0px, 0px)',
            },
        }}
        text={
            ({ value }) => `${value}%`
        }
        margin = {{ bottom: 25 }}
    />
};