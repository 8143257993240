import React from "react";
import { CButton } from "../Features/CButton";

export const Header = (props) => {
  return <>
    <header id="header" className="mobile-hidden">
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-md-5 intro-text" style={{"backgroundColor": "transparent"}}>
              <h1>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
              <CButton href="#contact" label={props.data.contact} />
            </div>
            <div className="col-md-1 mobile-hidden" style={{"backgroundColor": "transparent"}}></div>
            <div className="col-md-6 intro-photo mobile-hidden" style={{"backgroundColor": "transparent"}}></div>
          </div>
        </div>
      </div>
    </header>

    <div id="header-mobile" className="mobile-show">
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12 intro-text">
              <h1>{props.data ? props.data.title : "Loading"}</h1>
              <h3>{props.data?.paragraph}</h3>
              <CButton href="#contact" label={props.data.contact} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>;
};
