import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navigation } from "../components/navigation";
// import SmoothScroll from "smooth-scroll";
import { Footer } from "../components/footer";

// export const scroll = new SmoothScroll('a[href*="#"]:or([href="/about#"])', {
//     speed: 10000,
//     speedAsDuration: true
// });

const Main = (props) => {
    const location = useLocation();

    /*
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(props.data);
    }, [props.data]);
    */

    return <>
        <Navigation data={props.data} pathname={location.pathname} />
            <Outlet />
        <Footer data={props.footer} />
    </>
};

export default Main;