import React from "react";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { Product } from "../components/product";
import { Services } from "../components/services";
import { Contact } from "../components/contact";
import { useTranslation } from "react-i18next";

export const Homepage = (props) => {
    
    const { t } = useTranslation('content');

    // const [landingPageData, setLandingPageData] = useState({});
    // useEffect(() => {
    //     setLandingPageData(props.data);
    // }, [props.data]);

    return <>
        <Header data={t("Header")} />
        <Features data={t("Features")} />
        <Product data={t("Product")} />
        <Services data={t("Services")} />
        <Contact data={t("Contact")} />

        
        {/* <Header data={landingPageData.Header} />
        <Features data={landingPageData.Features} />
        <Product data={landingPageData.Product} />
        <Services data={landingPageData.Services} />
        <Contact data={landingPageData.Contact} /> */}
    </>
};