import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./layouts/main";
import { Homepage } from "./components/homepage";
import { About } from "./components/about";
import { Privacy } from "./components/privacy";
import { useTranslation } from "react-i18next";
// import i18n from "./i18n/index";
import { defineLang } from "./helpers/langSetter";

const App = () => {
  defineLang();

  const { t } = useTranslation('content');
  //console.log('idioma seleccionado en este momento:', i18n.language);

  return <>
      <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
        <Routes>
          <Route element={<Main data={t("Navigation")} footer={t("Footer")} />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<About data={t("About")} />} />
            <Route path="/privacy" element={<Privacy data={t("Privacy")} />} />
          </Route>
        </Routes>
      </Router>
    </>
};

export default App;
