import React from "react";
import LanguageSelector from "../Features/LanguageSelector";

export const Navigation = (props) => {

  const img = new Image();
  img.src = props.data?.logo;
  img.className = "brand-logo";
  img.alt = "MyClient";
  img.onload = () => {
    document.getElementById("logo").innerHTML = ""; // Limpio el espacio
    document.getElementById("logo").appendChild(img); // luego append el logo
  }

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          {/* Agrego la imagen desde javascript Object para evitar que se vea un recuadro durante la carga del logo */}
          <a className="navbar-brand page-scroll" href="/"><div id="logo"></div></a>{" "}
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          {props.pathname !== "/about" && props.pathname !== "/privacy" && 
          <>
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#product" className="page-scroll">
                {props.data?.product}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {props.data?.features}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {props.data?.contact}
              </a>
            </li>
            <li>
              <a href="https://app.my-client.co" className="page-scroll">
                Login
              </a>
            </li>
            <li style={{backgroundColor: "transparent"}}>
              <span className="lang">{"| "}</span><LanguageSelector />
            </li>
          </ul>
          </>}

          {(props.pathname === "/about" || props.pathname === "/privacy") && 
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/" className="page-scroll">
                {props.data?.home}
              </a>
            </li>
            <li>
              {/* <a href="#about" className="page-scroll"> */}
              <a href={`#${props.pathname.split('/')[1]}`} className="page-scroll">
                {props.data[props.pathname.split('/')[1]]}
              </a>
            </li>
            <li>
              <a href="/#contact" className="page-scroll">
                {props.data?.contact}
              </a>
            </li>
            <li>
              <a href="https://my-client.co" className="page-scroll">
                Login
              </a>
            </li>
            <li style={{backgroundColor: "transparent"}}>
              <span className="lang">{"| "}</span><LanguageSelector />
            </li>
          </ul>}
        </div>
      </div>
    </nav>
  );
};
