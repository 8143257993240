import React from "react";
import { useState } from "react";
import emailjs from "emailjs-com";
// import { CButton } from "../Features/CButton";

const initialState = {
  name: "",
  email: "",
  phone: "",
  website: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, website, message }, setState] = useState(initialState);
  const [formSent, setFormSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState({...initialState});
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name, email, phone, website, message);
    
    if(formSent === false) {
      setFormSent(true);
      emailjs
      .sendForm("service_vkc2tuf", "template_6nrpl2b", e.target, "3Fw_59qqvhzcdnTqb")
      .then(
        (result) => {
          // console.log(result);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
    }
  };
  return <>
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-9">
            <div className="row">
              
              <h2>{props.data?.title}</h2>
              <p>{props.data?.text}</p>
              
              <div className="col-md-11 form-box">
                <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder={props.data?.name_placeholder}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder={props.data?.email_placeholder}
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder={props.data?.phone_placeholder}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="website"
                          name="website"
                          className="form-control"
                          placeholder={props.data?.website_placeholder}
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder={props.data?.message_placeholder}
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  {formSent && <div id="success" className="row col-md-8"><div className="alert alert-success text-center" role="alert">{props.data?.alert}</div></div>}
                  {!formSent && <button type="submit" className="btn btn-custom btn-lg">
                    {props.data?.contact}
                  </button>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
};
