import React from "react";
import CTable from "../Features/CTable";
import { headers, data } from "../data/table_data";
import { data as order } from "../data/order_data";
import { CLineChart } from "../Features/CLineChart";
import { CGauge } from "../Features/CGauge";
import { COrder } from "../Features/COrder";
import { CButton } from "../Features/CButton";

export const Services = (props) => {
  return <>
    <div id="services" className="mobile-hidden">
      <div className="container">
        <div className="section-title">
          <h2>{props.data?.title}</h2>
        </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}

        <div className="row section" style={{backgroundColor: "transparent"}}>
          <div className="col-md-4 mobile-hidden" style={{backgroundColor: "transparent"}}>
            <div className="charts text-center table-shadow">
              <h4>{props.data?.linechart}</h4>
              <CLineChart />
            </div>
          </div>
          <div className="col-md-4 mobile-hidden" style={{backgroundColor: "transparent"}}>
            <div className="charts text-center table-shadow">
              <h4>{props.data?.gauge}</h4>
              <CGauge />
            </div>
          </div>

          <div className="col-md-4" style={{backgroundColor: "transparent"}}><div className="services-text"><div dangerouslySetInnerHTML={{__html: props.data?.dashboard}}></div></div></div>
        </div>

        <div className="row section" style={{backgroundColor: "transparent"}}>
          <div className="col-md-4" style={{backgroundColor: "transparent"}}><div className="services-text"><div dangerouslySetInnerHTML={{__html: props.data?.table}}></div></div></div>
          <div className="col-md-8 mobile-hidden" style={{backgroundColor: "transparent"}}><CTable headers={headers} data={data} placeholder={props.data?.placeholder} /></div>
        </div>

        <div className="row section last-service" style={{backgroundColor: "transparent"}}>
          <div className="col-md-8 mobile-hidden"><COrder order={order} data={props.data} /></div>
          <div className="col-md-4" style={{backgroundColor: "transparent"}}><div className="services-text"><div dangerouslySetInnerHTML={{__html: props.data?.order}}></div></div></div>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
          <br /><br /><br />
          <h2><div dangerouslySetInnerHTML={{__html: props.data?.more}}></div></h2>
          <CButton href="#contact" label={props.data.contact} />
          </div>
        </div>

      </div>
    </div>


    <div id="services-mobile" className="mobile-show">
      <div className="container">
        <div className="section-title">
          <h2>{props.data?.title}</h2>
        </div>

        <div className="row section" style={{backgroundColor: "transparent"}}>
          <div className="col-md-4" style={{backgroundColor: "transparent"}}>
            <div className="charts text-center table-shadow">
              <h4>{props.data?.linechart}</h4>
              <CLineChart />
            </div>
          </div>

          <div className="col-md-4" style={{backgroundColor: "transparent"}}>
            <div className="services-text" dangerouslySetInnerHTML={{__html: props.data?.dashboard}}></div>
          </div>
        </div>

        <div className="row section" style={{backgroundColor: "transparent"}}>
          <div className="col-md-8" style={{backgroundColor: "transparent"}}>
            <CTable headers={headers} data={data} placeholder={props.data?.placeholder} />
          </div>
          <div className="col-md-4" style={{backgroundColor: "transparent"}}><div className="services-text"><div dangerouslySetInnerHTML={{__html: props.data?.table}}></div></div></div>
        </div>

        <div className="row section" style={{backgroundColor: "transparent"}}>
          <div className="col-md-8"><COrder order={order} data={props.data} /></div>
          <div className="col-md-4" style={{backgroundColor: "transparent"}}><div className="services-text last"><div dangerouslySetInnerHTML={{__html: props.data?.order}}></div></div></div>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <h2><div dangerouslySetInnerHTML={{__html: props.data?.more}}></div></h2>
            <CButton href="#contact" label={props.data.contact} />
          </div>
        </div>
      </div>
    </div>
  </>;
};