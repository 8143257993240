import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import contentEN from './locales/en_content.json';
import contentES from './locales/es_content.json';

const resources = {
    en: {
        content: contentEN
    },
    es: {
        content: contentES
    }
};

// Set default language = navigator, but if navigator is different from en or es, then en is set.
const default_language = navigator.language.split("-")[0] !== 'es' && navigator.language.split("-")[0] !== 'en' ? 'en' : navigator.language.split("-")[0];

i18n
.use(initReactI18next)
.init({
    resources,
    lng: default_language, // "es", // default language
    debug: true,
    fallbackLng: "en", // use if selected language is not available
    interpolation: {
        escapeValue: false
    },
    ns: ["content"],
    returnObjects: true
});

export default i18n;