export const headers = ['Invoice Number', 'Date', 'Due Date', 'Status', 'Total','Outstanding'];

export const data = [
    {
        InvoiceNumber: "4159360",
        Date: "10/25/2024", 
        DueDate: "11/25/2024",
        Status: "Paid",
        Total: 35000,
        OutstandingAmount: 0
    },
    {
        InvoiceNumber: "4339361",
        Date: "10/25/2024", 
        DueDate: "12/25/2024",
        Status: "Pending",
        Total: 42000,
        OutstandingAmount: 42000
    },
    {
        InvoiceNumber: "4159440",
        Date: "11/30/2024", 
        DueDate: "02/28/2025",
        Status: "Overdue",
        Total: 28000,
        OutstandingAmount: 28000
    },
    {
        InvoiceNumber: "4229510",
        Date: "01/12/2024", 
        DueDate: "04/12/2024",
        Status: "Overdue",
        Total: 9000,
        OutstandingAmount: 9000
    },
    {
        InvoiceNumber: "4339560",
        Date: "03/15/2024", 
        DueDate: "06/15/2024",
        Status: "Pending",
        Total: 17000,
        OutstandingAmount: 17000
    }
];