import React from "react";
import { CButton } from "../Features/CButton";

export const Product = (props) => {
  return (
    <div id="product">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12 section-title">
            <h2><div dangerouslySetInnerHTML={{__html: props.data?.title}}></div></h2>
          </div>
          <div className="col-xs-12 col-md-12">
            <p dangerouslySetInnerHTML={{__html: props.data?.product}}></p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="product-text">
              <div className="col-lg-6 col-sm-12">
                <ul>
                  <li>{props.data?.pros1[0]}</li>
                  <li>{props.data?.pros1[1]}</li>
                  <li>{props.data?.pros1[2]}</li>
                </ul>
              </div>
              <div className="col-lg-6 col-sm-12">
                <ul>
                  <li>{props.data?.pros2[0]}</li>
                  <li>{props.data?.pros2[1]}</li>
                  <li>{props.data?.pros2[2]}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row text-center button-top">
          <CButton href="#contact" label={props.data.contact} />
        </div>
      </div>
    </div>
  );
};
