import i18n from "../i18n/index";
import { getCookie, setCookie } from "./cookieHandler";

/**
 * This function sets the language based on navigator (browser language) and cookie (user selection)
 * @returns true.
 */
export const defineLang = () => {
    const lang = getCookie('lang');

    if(lang) {
        if(lang !== i18n.language) {
            setLang(lang);
        }
    } else {
        const new_lang = navigator.language.split("-")[0] !== 'es' && navigator.language.split("-")[0] !== 'en' ? 'en' : navigator.language.split("-")[0];
        setLang(new_lang);
    }
    
    return true;
};

export const setLang = (lang) => {
    setCookie('lang',lang,{expires: 730});
    i18n.changeLanguage(lang); // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
};