import React from "react";

export const Features = (props) => {
  return <>
    <div id="features">
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-title">
            <h2><div dangerouslySetInnerHTML={{__html: props.data?.title}}></div></h2>
          </div>
          <div className="col-md-12 subtitle">
            <p><span dangerouslySetInnerHTML={{__html: props.data?.subtitle}}></span></p>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-10 col-xs-offset-1 col-md-4 col-md-offset-4 text-center"><h3><div dangerouslySetInnerHTML={{__html:props.data?.solution}}></div></h3></div>
        </div>
      </div>
    </div>
  </>;
};
