import React from "react";

export const Privacy = (props) => {
    return <>
    <div id="privacy">
        <div className="privacy-top">
            <div className="container">
            <div className="row">
                <div className="col-md-12 intro-text">
                <h1>{props.data?.title}</h1>
                <h3>{props.data?.subtitle}</h3>
                </div>
            </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-md-10 col-md-offset-1">
                    <div className="privacy-text">
                        <div dangerouslySetInnerHTML={{__html: props.data?.policy}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
};