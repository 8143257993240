import React from "react";
import { LineChart } from '@mui/x-charts';

export const CLineChart = (props) => {
    return <LineChart
        series={[
            { 
                data: [15, 23, 18, 20],
                label: '2024',
                valueFormatter: (v) => `$${v} M`
            },
            { 
                data: [10, 17, 20, 9],
                label: '2025',
                valueFormatter: (v) => `$${v} M`
            },
        ]}
        xAxis={[{ scaleType: 'point', data: ['Q1','Q2','Q3','Q4'] }]}

        height={250}
        slotProps={{
            legend:{
                labelStyle: { fontSize: "0.95em" },
                itemMarkWidth: 11,
                itemMarkHeight: 11,
                position: { vertical: 'bottom', horizontal: 'middle' }
            }
        }}
        margin = {{ top: 10, bottom: 60, right: 20, left: 40 }}
    />
};