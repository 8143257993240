import React from "react";

export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container" style={{backgroundColor: "transparent"}}>
          <div className="col-md-2 col-md-offset-2">
            <div className="lines"><b>Company</b></div>
            <div className="lines"><a href="/about">{props.data?.about}</a></div>
          </div>

          <div className="col-md-2">
            <div className="lines"><b>Legal</b></div>
            <div className="lines"><a href="/privacy">{props.data?.privacy}</a></div>
          </div>

          <div className="col-md-2">
            <div className="lines"><b>Contact</b></div>
            <div className="lines"><b><a href="/#contact">{props.data?.contact}</a></b></div>
            {/* <div className="lines"><b><a href="#contact">FAQs</a></b></div> */}
          </div>

          <div className="col-md-2">
            <div className="lines"><b>Social Media</b></div>
            <div className="lines">
              <a href="https://www.linkedin.com/company/my-client-co" target="blank">
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="container text-center final" style={{backgroundColor: "transparent"}}>
          <p>
            <a href="https://my-client.co" rel="nofollow">
              MyClient
            </a>
            {" "}
            &copy; {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};
