import React, { useState } from "react";

export const COrder = (props) => {
    const [qty, setQty] = useState(7);
    const [sent, setSent] = useState(false);

    const qtyInputHandler = (e) => {
        const re = /^[0-9\b]+$/;
        if(re.test(e.target.value) || e.target.value === '') {
            setQty(e.target.value);
        }
    }

    const submitHandler = () => {
        setSent(true);
    }

    return <div className="row">
        <div className="col-md-12 order-box table-shadow">
            <div className="order-box-title"><h4>Add new order</h4></div>
            <div className="row" style={{paddingLeft: 10}}>
                <div className="col-xs-4 product">{props.order?.product}</div>
                <div className="col-xs-4"><span className="badge rounded-pill bg-primary">{props.order?.status}</span></div>
            </div>
            <div className="row order-label" style={{paddingLeft: 10}}>
                <div className="col-xs-4">CPU</div>
                <div className="col-xs-4">Drive</div>
                <div className="col-xs-4">Price</div>
            </div>
            <div className="row" style={{paddingLeft: 10}}>
                <div className="col-xs-4">{props.order?.cpu}</div>
                <div className="col-xs-4">{props.order?.drive}</div>
                <div className="col-xs-4">{props.order?.price.toLocaleString('en-US', {minimumFractionDigits: 2, style: "currency", currency: "USD"})} +VAT</div>
            </div>

            <div className="row order-label" style={{paddingLeft: 10}}>
                <div className="col-xs-4">RAM</div>
                <div className="col-xs-4">Power Supply</div>
                <div className="col-xs-4">Quantity</div>
            </div>
            <div className="row" style={{paddingLeft: 10}}>
                <div className="col-xs-4">{props.order?.ram}</div>
                <div className="col-xs-4">{props.order?.power}</div>
                <div className="col-xs-4"><input type="text" className="form-control" value={qty} onChange={qtyInputHandler} /></div>
            </div>
            <div className="row">
                {sent === false && <div className="col-xs-4 col-xs-offset-8 pt-4"><button type="button" className="btn btn-primary" onClick={submitHandler}>Send order</button></div>}
                {sent === true && <div className="col-md-4 col-md-offset-8 pt-4"><div className="alert alert-success text-center" role="alert">{props.data?.alert}</div></div>}
            </div>
        </div>
    </div>
};