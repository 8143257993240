import React, { useState } from "react";

const CTable = (props) => {
    const [search, setSearch] = useState('');
    const criteria_root = ['InvoiceNumber','Date','Status','Total'];

    const multiFilter = (order, criteria_root) => {
        let filtered_orders = [];
        let orderItemFinal = [];

        if(!Array.isArray(orderItemFinal) || orderItemFinal === null) {
            return false;
        }
        
        if(search === ''){
            return order;
        } else {
            for(var i=0; i<criteria_root.length; i++) {
                order[criteria_root[i]]?.toString().toLowerCase().includes(search) ? filtered_orders.push(true) : filtered_orders.push(false);
            }
        }
        return filtered_orders?.includes(true) ? order : null;
    };

    return <div className="table-height">
        <div className="row">
            <div className="col-md-12">
                <input type="text" className="form-control" placeholder={props.placeholder} onChange = {(e) => setSearch(e.target.value.toLowerCase())} />
            </div>
        </div>
        <br/>

        <table className="table table-hover table-shadow">
            <thead>
                <tr>
                    {props.headers.map((title,index) => (
                        <th key={index}><div style={{"textAlign": title==='Total' || title==='Outstanding' ? "right" : 'left'}}>{title}</div></th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.data.filter((item) => multiFilter(item, criteria_root))
                .map((item) => {
                    let badge_class;
                    switch(item.Status) {
                        case 'Overdue':
                            badge_class = 'badge rounded-pill bg-danger';
                            break;
                        case 'Paid':
                            badge_class = 'badge rounded-pill bg-success';
                            break;
                        default:
                            badge_class = 'badge rounded-pill bg-light text-dark';
                            break;
                    }
                    return <tr key={item.InvoiceNumber} className={item.Status==='Overdue' ? null : null}>
                        <td><div className="">{item.InvoiceNumber}</div></td>
                        <td><div className="">{item.Date}</div></td>
                        <td><div className="">{item.DueDate}</div></td>
                        <td><div className={badge_class}>{item.Status}</div></td>
                        <td><div className="" style={{"textAlign": "right"}}>{item.Total.toLocaleString('en-US', {minimumFractionDigits: 2, style: "currency", currency: "USD"})}</div></td>
                        <td><div className="" style={{"textAlign": "right"}}>{item.OutstandingAmount.toLocaleString('en-US', {minimumFractionDigits: 2, style: "currency", currency: "USD"})}</div></td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
};

export default CTable;