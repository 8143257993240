import React from "react";

export const About = (props) => {

  // console.log(props); return false;
  return (
    <div id="about">
      <div className="about-top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 intro-text">
              <h1>{props.data?.title}</h1>
              <h3>{props.data?.subtitle}</h3>
            </div>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="about-text">
              <h3>{props.data?.beginning_title}</h3>
              <p>{props.data?.beginning}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="about-text">
              <h3>{props.data?.our_passion_title}</h3>
              <p>{props.data?.our_passion}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="about-text">
              <h3>{props.data?.why_title}</h3>
              <p>{props.data?.why}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="about-text final">
              <h3>{props.data?.why2_title}</h3>
              <p>{props.data?.why2}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
