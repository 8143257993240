import React, {useState} from "react";
import i18n from '../i18n/index';
import { Link } from "react-router-dom";
import { setLang } from "../helpers/langSetter";

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        //console.log(e);
        setLang(e);
        setSelectedLanguage(e);
    }
    
    return <>
        <React.Fragment key='lang'>
            {selectedLanguage === 'en' ? <Link onClick={() => chooseLanguage('es')}>ES</Link> : <Link onClick={() => chooseLanguage('en')}>EN</Link>}
        </React.Fragment>
    </>
};

export default LanguageSelector;